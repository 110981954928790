var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
export let AlertDialogService = class AlertDialogService {
    open = false;
    headerText;
    mainText;
    showContactInfo = true;
    constructor() {
        makeObservable(this);
    }
    handleOpen = () => {
        this.open = true;
    };
    handleClose = () => {
        this.open = false;
    };
    setData = (data) => {
        this.headerText = data.headerText ? data.headerText : '';
        this.mainText = data.mainText ? data.mainText : '';
        this.showContactInfo = data.showContactInfo ? data.showContactInfo : false;
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AlertDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], AlertDialogService.prototype, "headerText", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], AlertDialogService.prototype, "mainText", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AlertDialogService.prototype, "showContactInfo", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AlertDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AlertDialogService.prototype, "handleClose", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AlertDialogService.prototype, "setData", void 0);
AlertDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], AlertDialogService);
